import Vue from "vue";
import Router from "vue-router";
import { auth, db } from "@/firebaseConfig";

import ContactCreate from "./components/ContactCreate.vue";

import Admin from "./components/Admin.vue";

import ReleaseUpload from "./components/ReleaseUpload.vue";

import Dashboard from "./components/Dashboard.vue";
import ReleasesMyDraftsList from "./components/ReleasesMyDraftsList.vue";
import ReleasesMyReleasesList from "./components/ReleasesMyReleasesList.vue";
import ReleasesAdminList from "./components/ReleasesAdminList.vue";

import ReleasePreview from "./components/ReleasePreview.vue";
import ReleasePreview_Fuga from "./components/ReleasePreview_Fuga.vue";
import ReleasePreviewChanges from "./components/ReleasePreviewChanges.vue";

import ReleaseEdit from "./components/ReleaseEdit.vue";
import ReleaseTracksList from "./components/ReleaseTracksList.vue";
import ReleaseTrackEdit from "./components/ReleaseTrackEdit.vue";

import ReleaseBulkMetaUpload from "./components/ReleaseBulkMetaUpload.vue";
import ReleaseBulkAudioUpload from "./components/ReleaseBulkAudioUpload.vue";
import ReleaseBulkArtUpload from "./components/ReleaseBulkArtUpload.vue";

import ReleaseDDEXUpload from "./components/ReleaseDDEXUpload2.vue";

import Royalties from "./components/Royalties.vue";

import Users from "./components/Users.vue";
import UserCreate from "./components/UserCreate.vue";
import UserProfile from "./components/UserProfile.vue";
import MyUserProfile from "./components/MyUserProfile.vue";

import Orgs from "./components/Orgs.vue";
import OrgCreate from "./components/OrgCreate.vue";
import OrgProfile from "./components/OrgProfile.vue";

import Artists from "./components/Artists.vue";
import ArtistCreate from "./components/ArtistCreate.vue";
import ArtistProfile from "./components/ArtistProfile.vue";

import People from "./components/People.vue";
import PersonCreate from "./components/PersonCreate.vue";
import PersonProfile from "./components/PersonProfile.vue";

import Contacts from "./components/Contacts.vue";
// import ContactCreate from './components/ContactCreate.vue'
import ContactUpdate from "./components/ContactUpdate.vue";

import ContactProfile from "./components/ContactProfile.vue";

import MasterTrackList from "./components/MasterTrackList.vue";

import MTLProducts from "./components/MTLProducts.vue";

import DDEXFiles from "./components/DDEXFiles.vue";

import FugaMigration from "./components/FugaMigration.vue";

import FugaArtists from "./components/FugaArtists.vue";
import FugaPeople from "./components/FugaPeople.vue";
import FugaLabels from "./components/FugaLabels.vue";
import FugaProducts from "./components/FugaProducts.vue";
import FugaExport from "./components/FugaExport.vue";

import Login from "./components/Login.vue";
import Reset from "./components/Reset.vue";

//=========

import ReleaseFugaUpload from "./components/ReleaseFugaUpload.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: {
        requiresAuth: false,
      },
    },

    {
      path: "/reset",
      name: "Reset",
      component: Reset,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/",
      redirect: "/dashboard",
    },
    {
      path: "/upload",
      name: "Upload",
      component: ReleaseUpload,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
    },
    {
      path: "/admin",
      name: "Admin",
      component: Admin,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
    },
    {
      path: "/dashboard",
      name: "Dashboard",
      component: Dashboard,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/my-drafts",
      name: "ReleasesMyDraftsList",
      component: ReleasesMyDraftsList,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
    },
    {
      path: "/my-releases",
      name: "ReleasesMyReleasesList",
      component: ReleasesMyReleasesList,
      requiresAdmin: true,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/releases-admin",
      name: "ReleasesAdminList",
      component: ReleasesAdminList,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
    },
    {
      path: "/release-preview/:release_id",
      name: "ReleasePreview",
      component: ReleasePreview,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
    },
    {
      path: "/release-preview-fuga/:release_id",
      name: "ReleasePreview_Fuga",
      component: ReleasePreview_Fuga,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/release-preview-changes/:release_id",
      name: "ReleasePreviewChanges",
      component: ReleasePreviewChanges,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/release-edit/:release_id",
      name: "ReleaseEdit",
      component: ReleaseEdit,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
    },
    {
      path: "/release-tracks-list/:release_id",
      name: "ReleaseTracksList",
      component: ReleaseTracksList,

      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
    },
    {
      path: "/release-track-edit/:track_id",
      name: "ReleaseTrackEdit",
      component: ReleaseTrackEdit,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
    },
    {
      path: "/release-bulk-meta-upload",
      name: "ReleaseBulkMetaUpload",
      component: ReleaseBulkMetaUpload,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
    },
    {
      path: "/release-bulk-audio-upload/:release_id",
      name: "ReleaseBulkAudioUpload",
      component: ReleaseBulkAudioUpload,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
    },
    {
      path: "/release-bulk-art-upload/:release_id",
      name: "ReleaseBulkArtUpload",
      component: ReleaseBulkArtUpload,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
    },
    {
      path: "/release-ddex-upload/:release_id",
      name: "ReleaseDDEXUpload",
      component: ReleaseDDEXUpload,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
    },
    {
      path: "/royalties",
      name: "Royalties",
      component: Royalties,
      meta: {
        requiresAuth: true,
      },
    },

    // USERS

    {
      path: "/users",
      name: "Users",
      component: Users,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
    },
    {
      path: "/user-create",
      name: "UserCreate",
      component: UserCreate,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
    },
    {
      path: "/user-profile/:user_id",
      name: "UserProfile",
      component: UserProfile,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
    },

    {
      path: "/my-user-profile/:user_id",
      name: "MyUserProfile",
      component: MyUserProfile,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/orgs",
      name: "Orgs",
      component: Orgs,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
    },
    {
      path: "/org-create",
      name: "OrgCreate",
      component: OrgCreate,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
    },
    {
      path: "/org-profile/:org_id",
      name: "OrgProfile",
      component: OrgProfile,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
    },

    // ARTISTS

    {
      path: "/artists",
      name: "Artists",
      component: Artists,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
    },
    {
      path: "/artist-create",
      name: "ArtistCreate",
      component: ArtistCreate,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
    },
    {
      path: "/artist-profile/:artist_id",
      name: "ArtistProfile",
      component: ArtistProfile,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
    },

    // PEOPLE / CONTRIBUTORS

    {
      path: "/people",
      name: "People",
      component: People,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
    },
    {
      path: "/person-create",
      name: "PersonCreate",
      component: PersonCreate,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
    },
    {
      path: "/person-profile/:person_id",
      name: "PersonProfile",
      component: PersonProfile,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
    },

    // CONTACTS

    {
      path: "/sign-me-up",
      name: "ContactCreate",
      component: ContactCreate,
      meta: {
        requiresAuth: false,
        requiresAdmin: true,
      },
    },
    {
      path: "/contact_update/:contact_id",
      name: "ContactUpdate",
      component: ContactUpdate,
      meta: {
        requiresAuth: false,
        requiresAdmin: true,
        checkIfUser: true,
      },
    },
    {
      path: "/contacts",
      name: "Contacts",
      component: Contacts,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
    },
    {
      path: "/contact-profile/:contact_id",
      name: "ContactProfile",
      component: ContactProfile,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
    },

    // MASTER TRACK LIST

    {
      path: "/master-track-list",
      name: "MasterTrackList",
      component: MasterTrackList,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
    },

    // FUGA

    {
      path: "/fuga-migration",
      name: "FugaMigration",
      component: FugaMigration,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
    },

    {
      path: "/ddex-releases",
      name: "DDEXFiles",
      component: DDEXFiles,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
    },

    {
      path: "/mtl-products",
      name: "MTLProducts",
      component: MTLProducts,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
    },

    {
      path: "/fuga-artists",
      name: "FugaArtists",
      component: FugaArtists,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
    },
    {
      path: "/fuga-people",
      name: "FugaPeople",
      component: FugaPeople,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
    },
    {
      path: "/fuga-labels",
      name: "FugaLabels",
      component: FugaLabels,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
    },
    {
      path: "/fuga-products",
      name: "FugaProducts",
      component: FugaProducts,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
    },
    {
      path: "/fuga-export",
      name: "FugaExport",
      component: FugaExport,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
    },

    {
      path: "/release-fuga-upload/:release_id",
      name: "ReleaseFugaUpload",
      component: ReleaseFugaUpload,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
    },
  ],
});

// PREVENT CONTACTS WHO ARE USERS FROM UPDATING CONTACT INFORMATION
// TAKE THEM TO LOGIN PAGE INSTEAD

const isContactUser = async function (id) {
  const contactQuery = await db.collection("contacts").doc(id).get();
  const contact = contactQuery.data();
  return contact.is_user;
};

router.beforeEach(async (to, from, next) => {
  const checkIfUser = to.matched.some((x) => x.meta.checkIfUser);
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  const requiresAdmin = to.matched.some((x) => x.meta.requiresAdmin);
  const currentUser = auth.currentUser;
  const userProfile =
    currentUser && (await db.collection("users").doc(currentUser.uid).get());
  const isAdmin =
    userProfile &&
    (userProfile.data().role === "admin" ||
      userProfile.data().role === "superadmin");
  const IsUser = checkIfUser && (await isContactUser(to.params.contact_id));
  // const is_contract_signed = userProfile && userProfile.data().is_contract_signed || false

  if (!requiresAuth) {
    console.log("checkIfUser: " + checkIfUser);
    console.log("IsUser: " + IsUser);
    if (checkIfUser && IsUser) {
      next({
        path: "/login",
      });
    } else {
      next();
    }
  } else if (requiresAuth && !currentUser) {
    next({
      path: "/login",
    });
  } else if (requiresAuth && currentUser) {
    if (!requiresAdmin) {
      next();
    } else if (requiresAdmin && isAdmin) {
      next();
    } else if (requiresAdmin && !isAdmin) {
      next(false);
    }
  }
});

export default router;
