<template>
  <basicLayout>
    <div class="container-fluid">
      <div class="row m-1">
        <div class="col">
          <div class="text-center">
            <div class="col-xl-6 col-12 mx-auto">
              <div class="card p-3">
                <div class="card-body">
                  <h4 class="text-bold mb-3">WELCOME TO DRM!</h4>
                  <div class="text-start">
                    <h4 class="text-center fw-bold">
                      Access Our New Client Portal
                    </h4>

                    <div class="video-box">
                      <div style="padding: 56.25% 0 0; position: relative">
                        <iframe
                          src="https://player.vimeo.com/video/1024011229?h=fc257a68bc&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;byline=0&amp;controls=1&amp;title=0&amp;byline=0&amp;transparent=0"
                          frameborder="0"
                          allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                          style="
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                          "
                          title="The Orchard Workstation Overview (DRM Integration)"
                        ></iframe>
                      </div>
                    </div>

                    <h5 class="fw-bold text-uppercase">How to login</h5>

                    <p>
                      For first time users of the new portal, access the reset
                      password feature at
                      <a
                        href="https://login.distroauth.com/u/login"
                        target="_blank"
                        >this link</a
                      >
                      (by clicking 'don't remember your password') for
                      <b>The Orchard Workstation</b>.
                    </p>

                    <p>
                      When prompted for your email address, please your usual
                      login email and click "Send Reset Email."
                    </p>
                    <p>
                      You will then receive an email from
                      "access-donotreply@theorchard.com" with a link to set your
                      password. Please be sure to check your Spam or Junk folder
                      if you do not see an email in your inbox.
                    </p>
                    <p>
                      Enter your new password, reconfirm, and save your
                      selections.
                    </p>
                    <p>
                      You will then be asked to select and complete an extra
                      security authentication method.
                    </p>
                    <p>
                      Once you have selected the extra security method, you'll
                      be sent a 'Recovery Code' via your chosen method to copy
                      and enter.
                    </p>
                    <p>
                      Once you have entered this code, your password reset will
                      be activated and you'll be logged in.
                    </p>
                    <p>
                      Once logged in, you can add other users and set their
                      access permissions as needed.
                    </p>
                    <p>
                      Need help logging in? Email us at
                      <a href="mailto:info@drm.co.nz">info@drm.co.nz</a>
                    </p>

                    <p>
                      <b
                        >Once you have logged into the new portal, check out our
                        <a href="https://helpcenter.theorchard.com"
                          >Help Center</a
                        >, which has extensive FAQs, Video Tutorials and Product
                        Guides to help guide you through various sections. When
                        you're ready, feel free to start
                        <a
                          href="https://workstation.theorchard.com/project/new-create"
                          >uploading</a
                        >
                        your first releases.
                      </b>
                    </p>
                  </div>
                  <hr />
                  <div class="text-start">
                    <h4 class="text-center fw-bold">Statements and Payments</h4>
                    <p>
                      Throughout the rest of 2024, there will be no change to
                      your current payment experience. You can expect your first
                      statement in the Orchard Workstation by the end of January
                      2025, reflecting your December statement, for November
                      sales activity.
                    </p>
                    <p>
                      From January 2025 we will be moving to monthly Statements
                      and Payments!
                    </p>
                    <p>
                      Be sure to start downloading any historical statement
                      information you need to keep as the current DRM Portal and
                      all historical statements will no longer be available
                      starting in 2025. We will update you on when to expect the
                      last statement on the old DRM Portal.
                    </p>
                  </div>
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import basicLayout from "@/components/basicLayout";

// import { db } from "@/firebaseConfig";
// import { state } from "@/store";

// const orgs = db.collection("orgs");

export default {
  name: "dashboard",

  data() {
    return {
      display_orgs: [],
    };
  },
  components: { basicLayout },

  mounted() {
    console.log("mounted");
    document.body.classList.add("bg-c");
  },

  destroyed() {
    document.body.classList.remove("bg-c");
  },
};
</script>

<style scoped>
.drm-logo {
  max-width: 30%;
  height: auto;
}
.video-box {
  padding: 25px;
}

@media screen and (max-width: 768px) {
  .video-box {
    padding: 20px 0 20px 0;
  }
}
</style>
