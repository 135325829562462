<template>
  <div>
    <Spinner v-if="isLoading" />
    <nav
      class="navbar navbar-expand-sm navbar-dark bg-dark border-bottom fixed-top"
    >
      <router-link to="/" class="navbar-brand text-center ms-3">
        <img src="../assets/logo.png" width="auto" height="50" alt />
      </router-link>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item dropdown">
            <span
              class="m-3"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <div class="avatar me-3">
                <div class="avatar__letters">{{ user_initials }}</div>
              </div>
            </span>
            <ul
              class="dropdown-menu dropdown-menu-end me-3"
              aria-labelledby="navbarDropdown"
            >
              <li>
                <router-link
                  :to="{
                    name: 'MyUserProfile',
                    params: { user_id: currentUser.uid },
                  }"
                  class="dropdown-item"
                >
                  <user-icon size="1.0x" class="me-3"></user-icon>My Profile
                </router-link>
              </li>
              <li>
                <span @click="logout" class="dropdown-item" role="button">
                  <log-out-icon size="1.0x" class="me-3"></log-out-icon>Logout
                </span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>

    <div
      class="d-flex sticky-top"
      :class="{ toggled: toggle_menu }"
      id="wrapper"
    >
      <!-- Sidebar -->
      <div class="sidebar border-right" id="sidebar-wrapper">
        <div class="list-group list-group-flush sticky-top">
          <div>
            <router-link
              class="list-group-item list-group-item-action bg-transparent text-light"
              :to="{ name: 'Dashboard' }"
            >
              <grid-icon size="1.5x" class="custom-class"></grid-icon>
              <span class="d-none d-md-inline ms-3">&nbsp;Dashboard</span>
            </router-link>
          </div>
          <div v-if="!isAdmin">
            <a
              href="https://workstation.theorchard.com"
              target="_blank"
              class="list-group-item list-group-item-action bg-transparent text-orchard"
            >
              <music-icon
                size="1.5x"
                class="custom-class text-light"
              ></music-icon>
              <span class="d-none d-md-inline ms-3 fw-bold"
                >My Releases NEW</span
              >
            </a>

            <router-link
              class="list-group-item list-group-item-action bg-transparent text-light"
              :to="{ name: 'Royalties' }"
            >
              <percent-icon size="1.5x" class="custom-class"></percent-icon>
              <span class="d-none d-md-inline ms-3">My Royalties</span>
            </router-link>
          </div>
          <div v-if="isAdmin">
            <router-link
              class="list-group-item list-group-item-action bg-transparent text-light"
              :to="{ name: 'ReleasesAdminList' }"
            >
              <music-icon size="1.5x" class="custom-class"></music-icon>
              <span class="d-none d-md-inline ms-3">Pending Releases</span>
            </router-link>
            <router-link
              class="list-group-item list-group-item-action bg-transparent text-light border-bottom"
              :to="{ name: 'Contacts' }"
            >
              <users-icon size="1.5x" class="custom-class"></users-icon>
              <span class="d-none d-md-inline ms-3">Contacts</span>
            </router-link>
            <router-link
              class="list-group-item list-group-item-action bg-transparent text-light"
              :to="{ name: 'Users' }"
            >
              <users-icon size="1.5x" class="custom-class"></users-icon>
              <span class="d-none d-md-inline ms-3">Users</span>
            </router-link>

            <router-link
              class="list-group-item list-group-item-action bg-transparent text-light"
              :to="{ name: 'Orgs' }"
            >
              <globe-icon size="1.5x" class="custom-class"></globe-icon>
              <span class="d-none d-md-inline ms-3">Orgs</span>
            </router-link>

            <router-link
              class="list-group-item list-group-item-action bg-transparent text-light"
              :to="{ name: 'Artists' }"
            >
              <mic-icon size="1.5x" class="custom-class"></mic-icon>
              <span class="d-none d-md-inline ms-3">Artists</span>
            </router-link>

            <router-link
              class="list-group-item list-group-item-action bg-transparent text-light border-bottom"
              :to="{ name: 'People' }"
            >
              <user-plus-icon size="1.5x" class="custom-class"></user-plus-icon>
              <span class="d-none d-md-inline ms-3">People</span>
            </router-link>

            <router-link
              class="list-group-item list-group-item-action bg-transparent text-light"
              :to="{ name: 'Royalties' }"
            >
              <percent-icon size="1.5x" class="custom-class"></percent-icon>
              <span class="d-none d-md-inline ms-3">Royalties</span>
            </router-link>

            <router-link
              class="list-group-item list-group-item-action bg-transparent text-light border-bottom"
              :to="{ name: 'MasterTrackList' }"
            >
              <archive-icon size="1.5x" class="custom-class"></archive-icon>
              <span class="d-none d-md-inline ms-3">Master Track List</span>
            </router-link>

            <router-link
              class="list-group-item list-group-item-action bg-transparent text-light border-bottom"
              :to="{ name: 'Admin' }"
            >
              <settings-icon size="1.5x" class="custom-class"></settings-icon>
              <span class="d-none d-md-inline ms-3">Admin</span>
            </router-link>
            <!-- 
            <router-link
              class="list-group-item list-group-item-action bg-transparent text-light"
              :to="{ name: 'FugaMigration' }"
            >
              <settings-icon size="1.5x" class="text-warning"></settings-icon>
              <span class="d-none d-md-inline ms-3">FUGA Migration</span>
            </router-link> -->

            <!-- <router-link
              class="list-group-item list-group-item-action bg-transparent text-light border-bottom"
              :to="{ name: 'DDEXFiles' }">
              <code-icon
                size="1.5x"
                class="text-warning"></code-icon>
              <span class="d-none d-md-inline ms-3">DDEX Releases</span>
            </router-link> -->

            <router-link
              class="list-group-item list-group-item-action bg-transparent text-light border-bottom"
              :to="{ name: 'MTLProducts' }"
            >
              <archive-icon size="1.5x" class="text-warning"></archive-icon>
              <span class="d-none d-md-inline ms-3">MTL Products</span>
            </router-link>

            <router-link
              class="list-group-item list-group-item-action bg-transparent text-light"
              :to="{ name: 'FugaArtists' }"
            >
              <mic-icon size="1.5x" class="text-success"></mic-icon>
              <span class="d-none d-md-inline ms-3">FUGA Artists</span>
            </router-link>
            <router-link
              class="list-group-item list-group-item-action bg-transparent text-light"
              :to="{ name: 'FugaPeople' }"
            >
              <user-plus-icon size="1.5x" class="text-success"></user-plus-icon>
              <span class="d-none d-md-inline ms-3">FUGA People</span>
            </router-link>
            <router-link
              class="list-group-item list-group-item-action bg-transparent text-light"
              :to="{ name: 'FugaLabels' }"
            >
              <globe-icon size="1.5x" class="text-success"></globe-icon>
              <span class="d-none d-md-inline ms-3">FUGA Labels</span>
            </router-link>
            <router-link
              class="list-group-item list-group-item-action bg-transparent text-light"
              :to="{ name: 'FugaProducts' }"
            >
              <archive-icon size="1.5x" class="text-success"></archive-icon>
              <span class="d-none d-md-inline ms-3">FUGA Products</span>
            </router-link>
            <!-- <router-link
              class="list-group-item list-group-item-action bg-transparent text-light"
              :to="{ name: 'FugaExport' }"
            >
              <archive-icon size="1.5x" class="text-success"></archive-icon>
              <span class="d-none d-md-inline ms-3">FUGA Export</span>
            </router-link> -->
          </div>
        </div>
      </div>
      <!-- /#sidebar-wrapper -->
      <!-- <div class=""> -->
      <button
        class="btn btn-sm btn-dark sticky-top button-sidebar"
        @click="toggle_menu = !toggle_menu"
      >
        <chevrons-right-icon
          v-if="toggle_menu"
          size="1.5x"
          class="text-white"
        ></chevrons-right-icon>
        <chevrons-left-icon
          v-else
          size="1.5x"
          class="text-white"
        ></chevrons-left-icon>
      </button>
      <!-- </div> -->
      <!-- Page Content -->
      <div id="page-content-wrapper">
        <div class="container-fluid py-3">
          <slot />
        </div>
      </div>
      <!-- /#page-content-wrapper -->
    </div>
  </div>
</template>

<script>
// import navBar from "@/components/navBar";
import { auth } from "@/firebaseConfig.js";
import { state, getters, actions } from "@/store";
import Spinner from "@/components/Spinner";
import {
  SidebarIcon,
  Edit2Icon,
  MusicIcon,
  GridIcon,
  UsersIcon,
  GlobeIcon,
  MicIcon,
  UserPlusIcon,
  LogOutIcon,
  PercentIcon,
  UserIcon,
  CodeIcon,
  ChevronsLeftIcon,
  ChevronsRightIcon,
  SettingsIcon,
  ArchiveIcon,
} from "vue-feather-icons";

export default {
  name: "BasicLayout",
  components: {
    Spinner,
    SidebarIcon,
    Edit2Icon,
    MusicIcon,
    GridIcon,
    UsersIcon,
    CodeIcon,
    GlobeIcon,
    MicIcon,
    UserPlusIcon,
    LogOutIcon,
    PercentIcon,
    UserIcon,
    ChevronsLeftIcon,
    ChevronsRightIcon,
    SettingsIcon,
    ArchiveIcon,
  },
  data() {
    return {
      toggle_menu: false,
    };
  },

  methods: {
    logout: function () {
      auth
        .signOut()
        .then(() => {
          actions.clearUser();
          this.$router.push("/login");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    currentUser() {
      return auth.currentUser;
    },
    userProfile() {
      return state.userProfile;
    },
    isAdmin() {
      return (
        this.userProfile.role === "admin" ||
        this.userProfile.role === "superadmin"
      );
    },
    user_initials() {
      return this.userProfile.name
        .split(" ")
        .map((n) => n[0])
        .join("")
        .toUpperCase();
    },
    isLoading() {
      return getters.isLoading();
    },
  },
};
</script>

<style scoped>
.button-sidebar {
  display: block;
  margin-left: -8px;
  margin-right: 0;
  height: 64px;
  width: 36px;
  background-color: rgb(68, 61, 94);
  z-index: -1;
}

#sidebar-wrapper .router-link-exact-active {
  font-weight: 450;
  background-color: black !important;
}

#page-content-wrapper {
  margin-top: 80px;
}
.sticky-top {
  top: 78px;
}

/* #wrapper {
  margin-bottom: -80px;
} */

#sidebar-wrapper a:hover {
  font-weight: 500;
  background-color: rgb(34, 33, 33) !important;
}

#sidebar-wrapper {
  background-color: #29343f;
  min-height: 100vh;

  margin-left: -5rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
  width: 5rem;
}

#page-content-wrapper {
  min-width: 0;
  width: 100%;
  /* min-width: 100vw; */
}

.sidebar {
  background-color: rgb(103, 103, 243);
}

#wrapper #sidebar-wrapper {
  margin-left: 0;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: -5rem;
}

@media (min-width: 768px) {
  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #sidebar-wrapper .list-group {
    width: 15rem;
  }

  #sidebar-wrapper {
    margin-left: 0;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
  }
  .text-orchard {
    color: rgb(255, 137, 62);
  }
}
</style>
